<template>
    <v-card>
        <v-card-title>
            <h2>
                Retrieve Sub-Agent and Policy Number
            </h2>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col max-height="500px">
                    <v-autocomplete
                        v-model="selectedPolicyNumberArray"
                        :items="policyNumberArray"
                        filled
                        chips
                        label="Policy Number"
                        multiple
                    >
                        <template v-slot:selection="{ item, index }">
                            <v-chip
                                close
                                @click:close="removePolicyNumber(item)"
                                v-if="index < 50"
                            >
                                {{ item }}
                            </v-chip>
                            <v-chip
                                v-if="index === 50">
                                <span>{{ selectedPolicyNumberArray.length - 50 }} more selected... </span>
                            </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item }}
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h3>
                        Retrieve Sub-Agent and Policy Number using <a target="_blank" href="https://api.britecore.com/specifications/BriteCore/2.0.0/index.html#tag/policies/paths/~1api~1v2~1policies~1create_quote_extended/post">BriteCore API Tools</a>.
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        @click="getRiskDataArray()"
                    >Retrieve Sub-Agent and Policy Number CSV Mapping for Selected</v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        @click="selectAllPolicyNumbers()"
                    >Select All Policy Numbers</v-btn>
                </v-col>
                <!-- Used to update DB manually -->
                <!-- <v-col>
                    <v-btn
                        @click="setPolicyNumberArray()"
                    >set Policy Number</v-btn>
                </v-col> -->
            </v-row>
        </v-card-text>
    </v-card>
</template>
    
<script>

import { mapState, mapActions } from 'vuex'
import { createQuoteExtended, retrievePolicyTerms, retrieveRiskDetails, uploadToAttachmentsFolder } from '@/firebase/functions'
import { DB, doc, getDoc, setDoc } from '@/firebase/db'

export default {
        name: 'BritecoreTools',
        data() {
            return {
                guardianReportingEngineAsk: {},
                guardianReportingEngineAskArray: [],
                dialogLoading: false,
                riskDataArray: [],
                policyNumberArray: [],
                selectedPolicyNumberArray: [],
                updatePolicyNumberArrayDialogSuccess: false,
                dialogSuccess: false,
                dialogError: false,
                updateDatesDialogSuccess: false,
                updateDatesDialogError: false,
                workLogs: [],
                departmentsPaycor: [],
                csv: [],
                features: [],
                laborCodesPaycor: [],
                startDate: new Date(Date.now() - 12096e5).toISOString().substring(0,10),
                endDate: new Date(Date.now()).toISOString().substring(0,10),
                featureLevelWorkLogStartDate: new Date(Date.now() - 12096e5).toISOString().substring(0,10),
                featureLevelWorkLogEndDate: new Date(Date.now()).toISOString().substring(0,10),
                policyNumber: '',
            }
        },
        computed: {
            ...mapState(['user']),
        },
        methods: {
            ...mapActions(['monitorAuthState']),

            async testCreateQuoteExtended() {
                try {
                    let res = await createQuoteExtended({
                        policyNumber: 'LAWGRD-2023-1365',
                    })
    
                    return res
                } catch(err) {
                    alert('Function createQuoteExtended() error:\n\n  ' + err.message)
                    return err
                }
            },

            removePolicyNumber (item) {
                const selectedPolicyNumberIndex = this.selectedPolicyNumberArray.indexOf(item)
                if (selectedPolicyNumberIndex >= 0) this.selectedPolicyNumberArray.splice(selectedPolicyNumberIndex, 1)
            },

            async setPolicyNumberArray() {
                try {
                    const userRef = await doc(DB, 'user', this.user.uid)
                    await setDoc(userRef, { policyNumberArray: this.policyNumberArray }, { merge: true })
                    this.$emit('updatePolicyNumberArrayDialogSuccessMethod', true)
                    return userRef
                } catch (err) {
                    alert('Function saveStartEndDate() error:\n\n  ' + err.message)
                    return err
                }
            },

            selectAllPolicyNumbers() {
                this.selectedPolicyNumberArray = [...this.policyNumberArray]
            },

            async setSelectedPolicyNumberArray() {
                try {
                    const userRef = await doc(DB, 'user', this.user.uid)
                    await setDoc(userRef, { selectedPolicyNumberArray: this.selectedPolicyNumberArray }, { merge: true })
                    return userRef
                } catch (err) {
                    alert('Function saveStartEndDate() error:\n\n  ' + err.message)
                    return err
                }
            },

            async getPolicyNumberArray() {
                try {
                    const userSnap = await getDoc(doc(DB, 'user', this.user.uid))
                    this.policyNumberArray = userSnap.data().policyNumberArray ? userSnap.data().policyNumberArray : []

                    return userSnap
                } catch(err) {
                    alert('Function getPolicyNumberArray() error: \n\n  Unable to get policy numbers.')
                    return err
                }
            },

            async getSelectedPolicyNumberArray() {
                try {
                    const userSnap = await getDoc(doc(DB, 'user', this.user.uid))
                    this.selectedPolicyNumberArray = userSnap.data().selectedPolicyNumberArray ? userSnap.data().selectedPolicyNumberArray : []

                    return userSnap
                } catch(err) {
                    alert('Function getSelectedPolicyNumberArray() error: \n\n  Unable to get selected policy numbers.')
                    return err
                }
            },

            async getRiskDataArray() {
                this.$emit('dialogLoadingMethod', true)
                let promiseArray = []
                let policyNumberArrayOfArrays = []
                let selectedPolicyNumberArrayCopy = [...this.selectedPolicyNumberArray]
                let riskDataArrayCopy = []
                
                while(selectedPolicyNumberArrayCopy.length > 0) {
                    policyNumberArrayOfArrays.push([selectedPolicyNumberArrayCopy.splice(0,25)])
                }
                
                for(let i=0; i<policyNumberArrayOfArrays.length; i++) {
                    console.log(`policyNumberArrayOfArrays[${i}]:`, policyNumberArrayOfArrays[i])
                    policyNumberArrayOfArrays[i].forEach(selectedPolicyNumberArray => {
                        selectedPolicyNumberArray.forEach(async (policyNumber) => {
                            promiseArray.push(this.getRiskData(policyNumber))
                        })
                    });
                    await Promise.all(promiseArray).then(() => {
                        if (riskDataArrayCopy.length > 0) {
                            riskDataArrayCopy = riskDataArrayCopy.concat(this.riskDataArray)
                        } else {
                            riskDataArrayCopy = [...this.riskDataArray]
                        }
                        if (i === (policyNumberArrayOfArrays.length - 1)) {
                            this.formJsonObject(riskDataArrayCopy)
                        }
                    }).catch((err) => {
                        console.log(err)
                        this.$emit('dialogErrorMethod', true)
                        this.$emit('dialogLoadingMethod', false)
                    })
                    this.riskDataArray = []
                }

            },

            async getRiskData(policyNumber) {
                try {
                    let res = await retrievePolicyTerms({
                        policyNumber: policyNumber
                    })

                    // console.log('policyNumber:', policyNumber)
                    this.guardianReportingEngineAsk.policy_number = policyNumber
                    
                    let promiseArray = []
                    
                    if(Array.isArray(res.data.data)) {
                        res.data.data.forEach(policy => {
                            if(Array.isArray(policy.revisions)) {
                                policy.revisions.forEach(revision => {
                                    this.guardianReportingEngineAsk.commission_rate = revision.commission_rate
                                    this.guardianReportingEngineAsk.commission_rate_adjustment = revision.commission_rate_adjustment
                                    promiseArray.push(retrieveRiskDetails({
                                        riskId: revision.primary_property_id,
                                    }).then((riskData) => {
                                        return {
                                            policyNumber: policyNumber,
                                            riskData: riskData
                                        }
                                    }))
                                });
                            }
                        });
                    }

                    await Promise.all(promiseArray).then((values) => {
                        this.riskDataArray.push(values)
                    }).catch((err) => {
                        console.log('Promise Array error:', err)
                        // alert('Promise Array :/')
                    })
    
                    return res
                } catch(err) {
                    alert('Function retrievePolicyTerms() error:\n\n  ' + err.message)
                    return err
                }
            },
    
            formJsonObject(jsonObj) {
                const policyNumberSubagentMapping = []

                jsonObj.forEach(obj => {
                    let items = 
                        obj[0] != undefined && 
                        obj[0].riskData != undefined && 
                        obj[0].riskData.data != undefined && 
                        obj[0].riskData.data.data != undefined && 
                        obj[0].riskData.data.data.items != undefined && 
                        Array.isArray(obj[0].riskData.data.data.items) && 
                        obj[0].riskData.data.data.items.length > 0 ? obj[0].riskData.data.data.items : false

                    if (items) {
                        items.forEach((obj2) => {
                            if (obj2.name === "Rating Information") {
                                console.log('id of Rating Information item:', obj2.id)
                            }
                            let agencyContactId = null
                            if (obj2.questions != undefined && Array.isArray(obj2.questions) && obj2.questions.length > 0) {
                                obj2.questions.forEach(element => {
                                    try {
                                        if (element.label != undefined && element.label === "Agency Contact") {
                                            console.log('id of Agency Contact question:', element.id)
                                            agencyContactId = element.id
                                        } else {
                                            console.log('No existing Agency Contact question')
                                        }
                                    } catch (e) {
                                        console.log(e)
                                    }
                                });
                            }
                            if (obj2.builder_obj !== null && obj2.builder_obj !== undefined) {
                                if(Object.prototype.hasOwnProperty.call(obj2.builder_obj, 'questions')) {
                                    if (Object.prototype.hasOwnProperty.call(obj2.builder_obj.questions, agencyContactId)) {
                                        this.guardianReportingEngineAsk.subAgentTextFieldOutput = obj2.builder_obj.questions[agencyContactId][0].text
                                        console.log('sub-agent:', obj2.builder_obj.questions[agencyContactId][0].text, '\n\n')
                                        policyNumberSubagentMapping.push({
                                            policyNumber: obj[0].policyNumber,
                                            subAgent: obj2.builder_obj.questions[agencyContactId][0].text
                                        })
                                    }
                                }
                            }
                        })
                    }
                });
                if (policyNumberSubagentMapping.length > 0) {
                    this.convertJsonToCsv(policyNumberSubagentMapping)
                    this.$emit('dialogSuccessMethod', true)
                    this.$emit('dialogLoadingMethod', false)
                } else {
                    this.$emit('dialogLoadingMethod', false)
                    alert('This list of Policy Numbers contains no sub-agents.')
                }

            },

            convertJsonToCsv(object) {
                const headers = Object.keys(object[0]).toString();
    
                const main = object.map((item) => {
                    return Object.values(item).toString()
                })
    
                const csv = [headers, ...main].join('\n')
    
                this.startCsvDownload(csv)
            },

            async startCsvDownload(input) {
                const blob = new Blob([input], { type: 'application/csv' })
                const url = URL.createObjectURL(blob)
                const date = new Date()
                const dateString = (date.getMonth() + 1).toString() + '_' + (date.getDate()).toString() + '_' + (date.getFullYear()).toString()
                const a = document.createElement('a')
                let fileName = `subagent_policyNumber_mapping_${dateString}.csv`
                await this.uploadToAttachmentsFolderGuardian(blob, fileName)
                a.download = fileName
                a.href = url
    
                document.body.appendChild(a)
    
                a.click()
    
                a.remove()
    
                URL.revokeObjectURL(blob)
            },

            async uploadToAttachmentsFolderGuardian(blob, fileName) {
                let csvBase64 = await this.getBase64(blob)
                try {
                    let res = await uploadToAttachmentsFolder({
                        client: 'guardian',
                        csvBase64: csvBase64,
                        fileName: fileName
                    })
                    return res
                } catch(err) {
                    alert('Function uploadToAttachmentsFolder() error:\n\n  ' + err.message)
                    return err
                }
            },

            getBase64(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        console.log(reader.result);
                        resolve(reader.result.split(',')[1])
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                        reject(error)
                    };
                })
            },
        },
        async mounted() {
            this.monitorAuthState()
            await this.getPolicyNumberArray()
            await this.getSelectedPolicyNumberArray()
        },
        watch: {
            selectedPolicyNumberArray: async function() {
                this.setSelectedPolicyNumberArray()
            },
        }
    }
</script>

<style scoped>

</style>
