<template>
  <v-container
    style="
      padding: 0;
      margin-top: 122px;
    " 
    fluid
  >
    <v-container>
      <v-row v-if="user.email" align="center" justify="space-around">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="displayedUsers"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Account List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="!item.isAdmin"
                small
                class="mr-2"
                @click="addAdminRole(item)"
              >
                Add Admin Role
              </v-btn>
              <v-btn
                v-if="item.isAdmin"
                small
                class="mr-2"
                @click="removeAdminRole(item)"
              >
                Remove Admin Role
              </v-btn>
              <v-btn
                v-if="!item.isUser"
                small
                class="mr-2"
                @click="addUserRole(item)"
              >
                Add User Role
              </v-btn>
              <v-btn
                v-if="item.isUser"
                small
                class="mr-2"
                @click="removeUserRole(item)"
              >
                Remove User Role
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog
            v-model="loadingDialog"
            width="500"
        >
          <v-card>
              <v-card-title>
                  Loading!
              </v-card-title>
              <v-card-text>
                  <v-row
                      justify="center"
                      class="pa-5"
                  >
                      <v-progress-circular
                          indeterminate
                          color="primary"
                          :size="70"
                          :width="7"
                      ></v-progress-circular>
                  </v-row>
              </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <v-card>
                <v-card-title>
                    {{ dialogHeader }}
                </v-card-title>
                <v-card-text>
                    {{ dialogText }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="dialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </v-row>
      
    </v-container>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getDocs, collection, DB, setDoc, doc } from '../firebase/db'
import { addAdminRole, removeAdminRole, addUserRole, removeUserRole } from '../firebase/functions';

export default {
  name: 'Admin',
  data() {
    return {
      loadingDialog: false,
      dialogText: '',
      dialogHeader: '',
      dialog: false,
      users: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email' },
        { text: 'uid', value: 'uid' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    }
  },
  comments: {},
  computed: {
    ...mapState(['user']),
    displayedUsers() {
      let displayedUsers = []
      this.users.forEach((user) => {
        if (user.uid !== this.user.uid) {
          displayedUsers.push(user)
        }
      })
      return displayedUsers
    }
  },
  methods: {
    ...mapActions(['monitorAuthState']),
    async getUsers() {
      const users = await getDocs(collection(DB, "user"))
      this.users = []
      users.forEach((user) => {
        let userCopy = user.data()
        console.log(userCopy)
        userCopy.name = user.data().firstName + ' ' + user.data().lastName
        this.users.push(userCopy)
      })
      console.log(this.users)
    },
    async addAdminRole({ email, uid }) {
      try {
        this.loadingDialog = true
        const userRef = await doc(DB, 'user', uid)
        const result = await addAdminRole({
            email: email,
        })
        await setDoc(userRef, { isAdmin: true }, { merge: true })
        this.loadingDialog = false
        this.dialogText = result.data.message
        this.dialogHeader = 'Admin Status'
        this.dialog = true
        this.getUsers()
      } catch {
        this.loadingDialog = false
        this.dialogText = 'result.data.message'
        this.dialogHeader = 'Admin Status'
        this.dialog = true
        this.getUsers()
      }
    },
    async removeAdminRole({ email, uid }) {
      try {
        this.loadingDialog = true
        const userRef = await doc(DB, 'user', uid)
        const result = await removeAdminRole({
            email: email,
        })
        await setDoc(userRef, { isAdmin: false }, { merge: true })
        this.loadingDialog = false
        this.dialogText = result.data.message
        this.dialogHeader = 'Admin Status'
        this.dialog = true
        this.getUsers()
      } catch {
        this.loadingDialog = false
        this.dialogText = 'result.data.message'
        this.dialogHeader = 'Admin Status'
        this.dialog = true
        this.getUsers()
      }
    },
    async addUserRole({ email, uid }) {
      try {
        this.loadingDialog = true
        const userRef = await doc(DB, 'user', uid)
        const result = await addUserRole({
            email: email,
        })
        await setDoc(userRef, { isUser: true }, { merge: true })
        this.loadingDialog = false
        this.dialogText = result.data.message
        this.dialogHeader = 'User Status'
        this.dialog = true
        this.getUsers()
      } catch {
        this.loadingDialog = false
        this.dialogText = 'result.data.message'
        this.dialogHeader = 'User Status'
        this.dialog = true
        this.getUsers()
      }
    },
    async removeUserRole({ email, uid }) {
      try {
        this.loadingDialog = true
        const userRef = await doc(DB, 'user', uid)
        const result = await removeUserRole({
            email: email,
        })
        await setDoc(userRef, { isUser: false }, { merge: true })
        this.loadingDialog = false
        this.dialogText = result.data.message
        this.dialogHeader = 'User Status'
        this.dialog = true
        this.getUsers()
      } catch {
        this.loadingDialog = false
        this.dialogText = 'result.data.message'
        this.dialogHeader = 'User Status'
        this.dialog = true
        this.getUsers()
      }
    }
  },
  mounted() {
    this.monitorAuthState()
    this.getUsers()
  },
  components: {}
}
</script>