<template>
    <v-container fill-height>
        <v-tabs
            vertical
        >
        <!-- Name this so that we know this is for Guardian -->
            <v-tab>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-icon left>
                                mdi-hammer
                            </v-icon>
                            Guardian Tools
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab>
            <v-tab>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-icon left>
                                mdi-hammer
                            </v-icon>
                            Great Bay Tools
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab>
            <v-tab-item
                class="pa-10"
            >
                <v-tabs>
                    <v-tab>
                        Retrieve Sub-Agent and Policy Number
                    </v-tab>
                    <v-tab-item>
                        <GuardianTools
                            v-on:updatePolicyNumberArrayDialogSuccessMethod="updatePolicyNumberArrayDialogSuccessMethod"
                            v-on:dialogLoadingMethod="dialogLoadingMethod"
                            v-on:dialogSuccessMethod="dialogSuccessMethod"
                            v-on:dialogErrorMethod="dialogErrorMethod"
                        ></GuardianTools>
                    </v-tab-item>
                </v-tabs>
            </v-tab-item>
            <v-tab-item
                class="pa-10"
            >
                <v-tabs>
                    <v-tab>
                        Policy Term Premium Trends Report
                    </v-tab>
                    <v-tab>
                        Hazard Hub YoC by Policy Number
                    </v-tab>
                    <v-tab-item>
                        <GreatbayTrendsReport></GreatbayTrendsReport>
                    </v-tab-item>
                    <v-tab-item>
                        <GreatbayYocByPolicyNumber></GreatbayYocByPolicyNumber>
                    </v-tab-item>
                </v-tabs>
            </v-tab-item>
        </v-tabs>
        <v-row justify="center">
            <v-dialog
                v-model="updatePolicyNumberArrayDialogSuccess"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Success!
                    </v-card-title>
                    <v-card-text>
                        Policy Numbers Updated!
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="updatePolicyNumberArrayDialogSuccess = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="dialogLoading"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Loading!
                    </v-card-title>
                    <v-card-text>
                        <v-row
                            justify="center"
                            class="pa-5"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                :size="70"
                                :width="7"
                            ></v-progress-circular>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="dialogSuccess"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Success!
                    </v-card-title>
                    <v-card-text>
                        CSV Successfully Created
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="dialogSuccess = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="dialogError"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Error
                    </v-card-title>
                    <v-card-text>
                        There was an issue creating the CSV
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="dialogError = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
    
    <script>
    import { mapState, mapActions } from 'vuex'
    import GuardianTools from '../components/GuardianTools.vue'
    import GreatbayTrendsReport from '../components/GreatbayTrendsReport.vue'
    import GreatbayYocByPolicyNumber from '../components/GreatbayYocByPolicyNumber.vue'
    
    export default {
        components: {
            GuardianTools,
            GreatbayTrendsReport,
            GreatbayYocByPolicyNumber,
        },
        name: 'BritecoreTools',
        data() {
            return {
                dialogLoading: false,
                updatePolicyNumberArrayDialogSuccess: false,
                dialogSuccess: false,
                dialogError: false,
                updateDatesDialogSuccess: false,
                updateDatesDialogError: false,
            }
        },
        computed: {
            ...mapState(['user']),
        },
        methods: {
            ...mapActions(['monitorAuthState']),

            updatePolicyNumberArrayDialogSuccessMethod(value) {
                this.updatePolicyNumberArrayDialogSuccess = value
            },

            dialogLoadingMethod(value) {
                this.dialogLoading = value
            },

            dialogSuccessMethod(value) {
                this.dialogSuccess = value
            },

            dialogErrorMethod(value) {
                this.dialogError = value
            },
        },
        async mounted() {
            this.monitorAuthState()
        },
    }
    </script>
    
    <style scoped>
    
    </style>