<template>
    <div class="home">
      <v-container fill-height>
        <v-row align="center">
          <v-col>
            <v-card>
              <v-card-title>
                Non-Admin User
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <p>
                        Email or Message an admin to give you access to the full site. <br />
                        jesse.stewart@nlsnow.com 
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
    import { mapState, mapActions } from 'vuex'
    
    export default {
      name: 'NonAdminUser',
      data() {
        return {}
      },
      components: {},
      computed: {
        ...mapState(['user']),
      },
      methods: {
        ...mapActions(['monitorAuthState']),
      },
      mounted() {
        this.monitorAuthState()
      }
    }
  </script>
  
  <style scoped>
  
  </style>
  