<template>
    <v-container fill-height>
        <v-row v-if="!(user.isAdmin || user.isUser)" align="center">
            <v-col>
                <v-card>
                <v-card-title>
                    Non-Admin User
                </v-card-title>
                <v-card-text>
                    <v-row>
                    <v-col>
                        <p>
                            Email or Message an admin to give you access to the full site. <br />
                            jesse.stewart@nlsnow.com 
                        </p>
                    </v-col>
                    </v-row>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-tabs 
                vertical
            >
            <v-tab v-if="!user.refreshTokenPaycorSandbox">
                <v-icon left>
                    mdi-lock
                </v-icon>
                    Authorize Paycor Sandbox
            </v-tab>
    
            <v-tab v-if="startDate && endDate" class="my-10">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-icon left>
                                mdi-calendar
                            </v-icon>
                            Choose Dates
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            Start Date: {{ timeCardHoursStartDate }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            End Date: {{ timeCardHoursEndDate }}
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab>
            <v-tab v-else>
                <v-icon color="red" left>
                mdi-calendar
                </v-icon>
                    Choose Dates
            </v-tab>
    
            <v-tab>
                <v-icon v-if="timeCardPaycor.length > 0" left>
                    mdi-account
                </v-icon>
                <v-icon v-else color="red" left>
                    mdi-account
                </v-icon>
                    Timecards
            </v-tab>

            <v-tab-item 
                v-if="!user.refreshTokenPaycorSandbox"
                class="pa-10"
            >
                <oauth-component :useSandbox="true"></oauth-component>
            </v-tab-item>
    
            <v-tab-item 
                class="pa-10"
            >
                <v-card
                    class="mb-12"
                >
                    <v-card-title>
                        <h2>
                            Work Log Dates
                        </h2>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3>
                                    Choose the range of dates for pulling Paycor Timecards.
                                </h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col justify="space-around">
                                <h3>From Date:</h3>
                                <v-date-picker
                                    v-model="startDate"
                                ></v-date-picker>
                            </v-col>
                            <v-col justify="space-around">
                                <h3>To Date:</h3>
                                <v-date-picker
                                    v-model="endDate"
                                ></v-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    @click="saveStartEndDate()"
                                >Update Worklogs</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            
            <v-tab-item 
                class="pa-10"
            >
                <v-row>
                    <v-col>
                        {{ this.timeCardPaycor }}
                    </v-col>
                </v-row>
            </v-tab-item>
            <!-- Michael Banks Id: 264db854-b10f-0000-0000-0000836d0400 -->
            </v-tabs>
        </v-row>
    
        <v-row justify="center">
            <v-dialog
                v-model="updateDatesDialogSuccess"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Success!
                    </v-card-title>
                    <v-card-text>
                        Work Log Dates have been Successfully Updated <br/><br/>
                        Start Date: {{ startDate }} <br/>
                        End Date: {{ endDate }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="updateDatesDialogSuccess = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
                v-model="loading"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Loading!
                    </v-card-title>
                    <v-card-text>
                        <v-row
                            justify="center"
                            class="pa-5"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                :size="70"
                                :width="7"
                            ></v-progress-circular>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
    </template>
    
    <script>
    import OauthComponent from '../components/OauthComponent.vue'
    
    import { mapState, mapActions } from 'vuex'
    import { DB, doc, getDoc, setDoc } from '@/firebase/db'
    import axios from 'axios'
    
    export default {
        name: 'RegistrationLanding',
        data() {
            return {
                updateDatesDialogSuccess: false,
                loading: false,
                workLogs: [],
                employeesPaycor: [],
                departmentsPaycor: [],
                numAuthAttempts: 0,
                laborCodesPaycor: [],
                timeCardPaycor: [],
                startDate: new Date(Date.now() - 12096e5).toISOString().substring(0,10),
                endDate: new Date(Date.now()).toISOString().substring(0,10),
                timeCardHoursStartDate: new Date(Date.now() - 12096e5).toISOString().substring(0,10),
                timeCardHoursEndDate: new Date(Date.now()).toISOString().substring(0,10),
                laborCategoriesPaycor: []
            }
        },
        computed: {
            ...mapState(['user']),
        },
        methods: {
            ...mapActions(['monitorAuthState', 'updateUserState', 'refreshAccessTokenPaycorSandbox']),
    
            logWorklogs() {
                console.log('this.laborCodesPaycor:', this.laborCodesPaycor)
                console.log('this.laborCategoriesPaycor:', this.laborCategoriesPaycor)
                console.log('this.timeCardPaycor:', this.timeCardPaycor)
            },
    
            customEmployeeFilter(item, queryText) {
                console.log(item)
                let displayNameText = item.displayName ? item.displayName.toLowerCase() : false
                return displayNameText ? displayNameText.indexOf(queryText) > -1 : false
            },
    
            async getEmployeesPaycorByLegalEntityID(continuationToken) {
                try {
                    let res = {}
                    if (continuationToken) {
                        res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/employees?include=All&emailType=Home&continuationToken=${continuationToken}`, {
                            headers: {
                                'Accept': 'application/json',
                                'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                                'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                                'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                            }
                        })
                        this.employeesPaycor = this.employeesPaycor.concat(res.data.records)
                        if (res.data.hasMoreResults) {
                            await this.getEmployeesPaycorByLegalEntityID(res.data.continuationToken)
                        }
                    } else {
                        res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/employees?include=All&emailType=Home`, {
                            headers: {
                                'Accept': 'application/json',
                                'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                                'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                                'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                            }
                        })
                        this.employeesPaycor = res.data.records ? res.data.records : []
                        if (res.data.hasMoreResults) {
                            await this.getEmployeesPaycorByLegalEntityID(res.data.continuationToken)
                        }
                    }
                    console.log('getEmployeesPaycorByLegalEntityID()\nthis.employeesPaycor:', this.employeesPaycor)
                    this.numAuthAttempts = 0
                    return res
                } catch(err) {
                    if (err.response.status === 401 && !(this.numAuthAttempts > 0)) {
                        this.numAuthAttempts = this.numAuthAttempts + 1 // Prevents an infinite loop
                        await this.refreshAccessTokenPaycorSandbox()
                        this.getEmployeesPaycorByLegalEntityID()
                    } else {
                        this.numAuthAttempts = 0
                        alert('Function getEmployeesPaycorByLegalEntityID(): ' + err.message)
                    }
                }
            },
    
            async getDepartmentsPaycorByLegalEntityId() {
                try {
                    const res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/departments`, {
                        headers: {
                            'Accept': 'application/json',
                            'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                            'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                            'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                        }
                    })
                    this.departmentsPaycor = res.data.records
                    console.log('getDepartmentsPaycorByLegalEntityId()\nthis.departmentsPaycor:', this.departmentsPaycor)
                    this.numAuthAttempts = 0
                    return res
                } catch(err) {
                    if (err.response.status === 401 && !(this.numAuthAttempts > 0)) {
                        await this.refreshAccessTokenPaycorSandbox()
                        this.numAuthAttempts = this.numAuthAttempts + 1
                        this.getDepartmentsPaycorByLegalEntityId()
                    } else {
                        this.numAuthAttempts = 0
                        alert('Function getDepartmentsPaycorByLegalEntityId(): ' + err.message)
                    }
                    return err
                }
            },
    
            async loadStartEndDates() {
                const userRef = await doc(DB, 'user', this.user.uid)
                const userSnap = await getDoc(userRef)
    
                console.log('userSnap.data().timeCardHoursStartDate && userSnap.data().timeCardHoursEndDate:', userSnap.data().timeCardHoursStartDate && userSnap.data().timeCardHoursEndDate)
    
                if (userSnap.data().timeCardHoursStartDate && userSnap.data().timeCardHoursEndDate) {
                    this.startDate = userSnap.data().timeCardHoursStartDate
                    this.endDate = userSnap.data().timeCardHoursEndDate
                    this.timeCardHoursStartDate = userSnap.data().timeCardHoursStartDate
                    this.timeCardHoursEndDate = userSnap.data().timeCardHoursEndDate
                }
    
                return userSnap
            },
    
            async saveStartEndDate() {
                try {
                    this.loading = true
                    const userRef = await doc(DB, 'user', this.user.uid)

                    this.timeCardHoursStartDate = this.startDate
                    this.timeCardHoursEndDate = this.endDate

                    await setDoc(userRef, { timeCardHoursStartDate: this.timeCardHoursStartDate }, { merge: true })
                    await setDoc(userRef, { timeCardHoursEndDate: this.timeCardHoursEndDate }, { merge: true })
                    await this.getEmployeesTimeCardByLegalEntityId()

                    this.loading = false
                    this.updateDatesDialogSuccess = true
                    return userRef
                } catch (err) {
                    alert('Function saveStartEndDate() error:\n\n  ' + err.message)
                    return err
                }
            },
    
            async getLaborCategoriesByLegalEntityId() {
                console.log('getLaborCategoriesByLegalEntityId()\nthis.laborCategoriesPaycor:')
                try {
                    const res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/laborcategories`, {
                        headers: {
                            'Accept': 'application/json',
                            'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                            'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                            'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                        }
                    })
                    this.laborCategoriesPaycor = res.data.records
                    console.log(this.laborCategoriesPaycor)
                    this.numAuthAttempts = 0
                    return res
                } catch(err) {
                    if (err.response.status === 401 && !(this.numAuthAttempts > 0)) {
                        await this.refreshAccessTokenPaycorSandbox()
                        this.numAuthAttempts = this.numAuthAttempts + 1
                        this.getLaborCategoriesByLegalEntityId()
                    } else {
                        alert('Function laborCategoriesPaycorByLegalEntityId(): ', err.message)
                    }
                    return err
                }
            },    
    
            async getLaborCodesByLegalEntityId(continuationToken) {
                try {
                    let res = {}
                    if (continuationToken) {
                        res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/laborcodes?continuationToken=${continuationToken}`, {
                            headers: {
                                'Accept': 'application/json',
                                'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                                'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                                'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                            }
                        })
                        this.laborCodesPaycor = this.laborCodesPaycor.concat(res.data.records)
                        if (res.data.hasMoreResults) {
                            await this.getLaborCodesByLegalEntityId(res.data.continuationToken)
                        }
                    } else {
                        res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/laborcodes`, {
                            headers: {
                                'Accept': 'application/json',
                                'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                                'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                                'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                            }
                        })
    
                        this.laborCodesPaycor = res.data.records ? res.data.records : []
                        if (res.data.hasMoreResults) {
                            await this.getLaborCodesByLegalEntityId(res.data.continuationToken)
                        }
                    }
                    this.numAuthAttempts = 0
                    console.log('getLaborCodesByLegalEntityId()\nthis.laborCodesPaycor:', this.laborCodesPaycor)
                    return res
                } catch(err) {
                    if (err.response.status === 401 && !(this.numAuthAttempts > 0)) {
                        await this.refreshAccessTokenPaycorSandbox()
                        this.numAuthAttempts = this.numAuthAttempts + 1
                        this.getLaborCodesByLegalEntityId()
                    } else {
                        this.numAuthAttempts = 0
                        alert('Function getlaborCodesPaycorByLegalEntityId(): ', err.message)
                    }
                    return err
                }
            },

            async getEmployeesTimeCardByLegalEntityId(continuationToken) {
                try {
                    let res = {}
                    if (continuationToken) {
                        res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/timecard?continuationToken=${continuationToken}&startDate=${this.startDate}&endDate=${this.endDate}`, {
                            headers: {
                                'Accept': 'application/json',
                                'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                                'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                                'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                            }
                        })
                        this.timeCardPaycor = this.timeCardPaycor.concat(res.data.records)
                        if (res.data.hasMoreResults) {
                            await this.getLaborCodesByLegalEntityId(res.data.continuationToken)
                        }
                    } else {
                        res = await axios.get(`${process.env.VUE_APP_PAYCOR_API_URL_BASE_SANDBOX}/v1/legalentities/${process.env.VUE_APP_PAYCOR_LEGAL_ENTITY_ID_SANDBOX}/timecard?startDate=${this.startDate}&endDate=${this.endDate}`, {
                            headers: {
                                'Accept': 'application/json',
                                'Ocp-Apim-Subscription-Key': process.env.VUE_APP_PAYCOR_SUBSCRIPTION_KEY_SANDBOX,
                                'Authorization': `Bearer ${this.user.accessTokenPaycorSandbox}`,
                                'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                            }
                        })
    
                        this.timeCardPaycor = res.data.records ? res.data.records : []
                        if (res.data.hasMoreResults) {
                            await this.getEmployeesTimeCardByLegalEntityId(res.data.continuationToken)
                        }
                    }
                    this.numAuthAttempts = 0
                    console.log('getEmployeesTimeCardByLegalEntityId()\nthis.timeCardPaycor:', this.timeCardPaycor)
                    return res
                } catch(err) {
                    if (err.response.status === 401 && !(this.numAuthAttempts > 0)) {
                        await this.refreshAccessTokenPaycorSandbox()
                        this.numAuthAttempts = this.numAuthAttempts + 1
                        this.getEmployeesTimeCardByLegalEntityId()
                    } else {
                        this.numAuthAttempts = 0
                        alert('Function getEmployeesTimeCardByLegalEntityId(): ', err.message)
                    }
                    return err
                }
            },
        },
        components: {
            OauthComponent,
        },
        async mounted() {
            this.monitorAuthState()
            await this.loadStartEndDates()
            await this.getEmployeesTimeCardByLegalEntityId()
            await this.getLaborCategoriesByLegalEntityId()
        }
    }
    </script>
    
    <style scoped>
    
    </style>
    