<!-- 
    Inputs from PayrollCsv:
        1) payrollWorkLogStartDate
        2) payrollWorkLogEndDate
        
    Affects in PayrollCsv:
        1) payrollWorkLogStartDate
        2) payrollWorkLogEndDate
        3) workLogs

    Goal: 
        1) Set Worklog Start Date and End Date
        2) Update 7pace Worklogs to those within the new dates
 -->

<template>
    <v-card
        class="mb-12"
    >
        <v-card-title>
            <h2>
                Work Log Dates
            </h2>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <h3>
                        Choose the range of dates for importing work logs into Adaptive Work.
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col justify="space-around">
                    <h3>From Date:</h3>
                    <v-date-picker
                        v-model="startDate"
                    ></v-date-picker>
                </v-col>
                <v-col justify="space-around">
                    <h3>To Date:</h3>
                    <v-date-picker
                        v-model="endDate"
                    ></v-date-picker>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        @click="updateWorklogs()"
                    >Update Worklogs</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex';
import { DB, doc, setDoc } from '@/firebase/db'

export default {
    name: 'WorklogDates',
    data() {
        return {}
    },

    computed: {
        ...mapState(['user']),
    },

    methods: {
        saveStartEndDate() {
            return new Promise((resolve, reject) => {
                setDoc(doc(DB, 'user', this.user.uid), { 
                    payrollWorkLogStartDate: this.startDate,
                    payrollWorkLogEndDate: this.endDate
                }, { merge: true })
                    .then(() => {
                        this.updateDatesDialogSuccess = true
                        resolve(true)
                    }).catch(() => {
                        reject(false);
                    })
            })
        },

        async updateWorklogs() {
            let userDataUpdated = await this.saveStartEndDate()
            if (userDataUpdated) {
                this.$emit('updateWorklogDates', true)
            } else {
                this.$emit('updateWorklogDates', false)
            }
        }
    },

    props: {
        startDate: {
            type: String,
            default() {
                return ''
            }
        },
        endDate: {
            type: String,
            default() {
                return ''
            }
        }
    },
}
</script>

<style scoped>

</style>
