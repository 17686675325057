<template>
    <v-card>
        <v-card-title>
            <h2>
                Policy Term Premium Trends Report
            </h2>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <h3>
                        1) Download the SQL Query.
                    </h3>
                    <h3>
                        2) Run Downloaded Query on the Great Bay Database.
                    </h3>
                    <h3>
                        3) Export output as a CSV.
                    </h3>
                    <h3>
                        4) Upload CSV below
                    </h3>
                    <h3>
                        5) Click "Create Policy Term Premium Trends Report" Button.
                    </h3>
                    <br />
                    <h3>
                        **Report will automatically download.
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        @click="downloadSql()"
                    >Download SQL Query</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col max-height="500px">
                    <v-file-input
                        v-model="queryCsv"
                        show-size
                        label="MySQL Query CSV"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h3>
                        Upload CSV output of MySQL Query run script to get back Policy Term Premium Trends Report
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        @click="createPolicyTermPremiumTrendsReport()"
                    >Create Policy Term Premium Trends Report</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
    
<script>

import { mapState, mapActions } from 'vuex'

import { uploadToAttachmentsFolder } from '@/firebase/functions'

export default {
        name: 'GreatbayTrendsReport',
        data() {
            return {
                queryCsv: '',
                uniquePolicyNumbers: [],
                maxItr: 0,
            }
        },
        computed: {
            ...mapState(['user']),
        },
        methods: {
            ...mapActions(['monitorAuthState']),

            async createPolicyTermPremiumTrendsReport() {
                console.log('createPolicyTermPremiumTrendsReport()')
                console.log('queryCsv:', this.queryCsv)
                const reader = new FileReader()
                reader.readAsText(this.queryCsv)
                reader.onload = () => {
                    let csvToJson = this.csvJSON(reader.result)
                    let groupedRows = this.groupBy(csvToJson, ({ policyNumber }) => policyNumber)
                    // console.log(groupedRows)
                    let finalOutput = []
                    this.uniquePolicyNumbers.forEach(policyNumber => {
                        let finalRow = {}
                        let itr = 1
                        let currentGroup = groupedRows[policyNumber]

                        // Sort by policyTermDate
                        currentGroup.sort((a,b) => {
                            return new Date(a.policyTermDate) - new Date(b.policyTermDate);
                        })

                        // If multiple revisions on one policyTerm returns the oldest revison (minimum revisionDate)
                        let filtered = currentGroup.filter((currentRow, index, currentGroup) => {
                            let matchIndex = currentGroup.filter((row) => row.policyTermsId === currentRow.policyTermsId)
                            if (matchIndex.length > 1) {
                                let dateArray = matchIndex.map((row) => new Date(row.revisionDate).getTime())
                                return new Date(currentRow.revisionDate).getTime() === Math.min(...dateArray)
                            }
                            return true
                        })
                        
                        filtered.forEach(revisionRow => {
                            finalRow.policyNumber = revisionRow.policyNumber
                            finalRow.inceptionDate = revisionRow.inceptionDate
                            finalRow['Policy Term '+ itr] = revisionRow.writtenPremium
                            this.maxItr = this.maxItr < itr ? itr : this.maxItr
                            itr++
                        });
                        finalOutput.push(finalRow)
                    })
                    // console.log(finalOutput)
                    this.convertJsonToCsv(finalOutput)
                }
            },

            csvJSON(csv) {
                const lines = csv.split('\n')
                const result = []
                const headers = lines[0].split(',')

                for (let i = 1; i < lines.length; i++) {        
                    if (!lines[i])
                        continue
                    const obj = {}
                    const currentline = lines[i].split(',')

                    for (let j = 0; j < headers.length; j++) {
                        obj[headers[j]] = currentline[j]
                    }
                    result.push(obj)
                }
                return result
            },

            groupBy(array, keyFn) {
                return array.reduce((result, item) => {
                    const key = keyFn(item);
                    if (this.uniquePolicyNumbers.indexOf(key) === -1) { 
                        this.uniquePolicyNumbers.push(key)
                    }
                    result[key] = result[key] || [];
                    result[key].push(item);
                    return result;
                }, {});
            },

            convertJsonToCsv(object) {
                // Find Index with greatest number of Policy Terms
                const index = object.findIndex((arr) => {
                    return Object.keys(arr).length === this.maxItr + 2
                })

                const headers = Object.keys(object[index]).toString(); // this gives headers so need longest array here.

    
                const main = object.map((item) => {
                    return Object.values(item).toString()
                })
    
                const csv = [headers, ...main].join('\n')
    
                this.startCsvDownload(csv)
                this.uploadToAttachmentsFolderGreatBay(csv)
            },

            async uploadToAttachmentsFolderGreatBay(file) {
                const blob = new Blob([file], { type: 'text/csv' })
                let csvBase64 = await this.getBase64(blob)
                console.log(csvBase64)
                try {
                    let res = await uploadToAttachmentsFolder({
                        client: 'greatbay',
                        csvBase64: csvBase64,
                        fileName: "Policy Term Premium Trends.csv"
                    })
    
                    return res
                } catch(err) {
                    alert('Function uploadToAttachmentsFolder() error:\n\n  ' + err.message)
                    return err
                }
            },

            startCsvDownload(input) {
                const blob = new Blob([input], { type: 'text/csv' })
                const url = URL.createObjectURL(blob)
                const date = new Date()
                const dateString = (date.getMonth() + 1).toString() + '_' + (date.getDate()).toString() + '_' + (date.getFullYear()).toString()
    
                const a = document.createElement('a')
                a.download = `Policy Term Premium Trends (${dateString}).csv`
                a.href = url
    
                document.body.appendChild(a)
    
                a.click()
    
                a.remove()
    
                URL.revokeObjectURL(blob)
            },
            
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        console.log(reader.result);
                        resolve(reader.result.split(',')[1])
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                        reject(error)
                    };
                })
            },

            downloadSql() {
                const query = `SELECT 
    policies.policyNumber,
    policies.inceptionDate,
    policy_terms.id AS 'policyTermsId',
    revisions.policyId AS 'policyId',
    revisions.policyStatus AS 'policyStatus',
    revisions.revisionState AS 'revisionState',
    revisions.revisionDate AS 'revisionDate',
    policy_terms.effectiveDate AS 'policyTermDate',
    effective_dates.effectiveDate AS 'led',
    revisions.writtenPremium AS 'writtenPremium',
    revisions.writtenFee AS 'writtenFee',
    revisions.writtenPremiumDelta AS 'writtenPremiumDelta',
    revisions.annualPremium AS 'annualPremium',
    revisions.annualFee AS 'annualFee'
FROM
    greatbay.policies AS policies
        JOIN
    greatbay.revisions AS revisions ON revisions.policyId = policies.id
        JOIN
    greatbay.policy_terms AS policy_terms ON revisions.policyTermId = policy_terms.id
        JOIN
    greatbay.policy_types AS policy_types ON policy_types.id = revisions.policyTypeId
        JOIN
    greatbay.effective_dates AS effective_dates ON effective_dates.id = policy_types.effectiveId
WHERE
    revisions.revisionState IN ('committed' , 'pending')
        AND revisions.policyStatus IN ('Active' , 'Cancellation Pending, Non-Payment of Premium')
ORDER BY
    policies.policyNumber asc, effective_dates.effectiveDate asc;`

                const blob = new Blob([query], { type: 'application/sql' })
                const url = URL.createObjectURL(blob)
    
                const a = document.createElement('a')
                a.download = `Policy_Term_Premium_Trends_Query.sql`
                a.href = url
    
                document.body.appendChild(a)
    
                a.click()
    
                a.remove()
    
                URL.revokeObjectURL(blob)
            },
            
        }
    }
</script>

<style scoped>

</style>
