<template>
<v-container fill-height>
    <v-row v-if="!(user.isAdmin || user.isUser)" align="center">
        <v-col>
            <v-card>
            <v-card-title>
                Non-Admin User
            </v-card-title>
            <v-card-text>
                <v-row>
                <v-col>
                    <p>
                        Email or Message an admin to give you access to the full site. <br />
                        jesse.stewart@nlsnow.com 
                    </p>
                </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-tabs 
            vertical
        >
        <v-tab v-if="!user.refreshTokenPaycor">
            <v-icon left>
                mdi-lock
            </v-icon>
                Authorize Paycor
        </v-tab>

        <v-tab v-if="payrollWorkLogStartDate && payrollWorkLogEndDate" class="my-10">
            <v-container>
                <v-row>
                    <v-col>
                        <v-icon left>
                            mdi-calendar
                        </v-icon>
                        Choose Dates
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Start Date: {{ payrollWorkLogStartDate }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        End Date: {{ payrollWorkLogEndDate }}
                    </v-col>
                </v-row>
            </v-container>
        </v-tab>
        <v-tab v-else>
            <v-icon color="red" left>
            mdi-calendar
            </v-icon>
                Choose Dates
        </v-tab>

        <v-tab>
            <v-icon left>
                mdi-account
            </v-icon>
            Employee Mapping
        </v-tab>
        <v-tab>
            <v-icon v-if="epicWorkItemMapping" left>
            mdi-briefcase
            </v-icon>
            <v-icon v-else color="red" left>
            mdi-briefcase
            </v-icon>
            Epic / Work Item Mapping
        </v-tab>
        <v-tab>
            <v-icon v-if="canUpdateAdaptiveWork" left>
            mdi-wrench
            </v-icon>
            <v-icon v-else color="red" left>
            mdi-wrench
            </v-icon>
            Update Adaptive Work
        </v-tab>

        <v-tab-item 
            class="pa-10"
        >
            <worklog-dates
                :key="worklogDatesMappingKey"
                :startDate="payrollWorkLogStartDate"
                :endDate="payrollWorkLogEndDate"
                v-on:updateWorklogDates="updateWorklogDates"
            ></worklog-dates>
        </v-tab-item>
        
        <v-tab-item 
            class="pa-10"
        >
            <v-row>
                <v-col>
                    <v-autocomplete
                        v-model="selected7paceEmployees"
                        :items="employees7pace"
                        :filter="customEmployeeFilter"
                        filled
                        chips
                        label="Employees"
                        return-object
                        multiple
                        :item-value="item => item.id"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip
                                close
                                @click:close="removeEmployee(item)"
                            >
                                {{ item.displayName }}
                            </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.displayName }}
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-btn @click="updateSelectedEmployees">Save Selected Employees</v-btn>
        </v-tab-item>

        <v-tab-item 
            class="pa-10"
        >
            <!-- <labor-code-mapping
                :key="laborCodeMappingKey"
                :epics="epics"
                :epicWorkItemMapping="epicWorkItemMapping"
                :activityTypes="activityTypes7pace"
                :workItemsAdaptiveWork="workItemsAdaptiveWork"
                v-on:updateEpicMapping="updateEpicMapping"
            ></labor-code-mapping> -->
            <work-item-mapping
                :key="workItemMappingKey"
                :epics="epics"
                :epicWorkItemMapping="epicWorkItemMapping"
                :activityTypes="activityTypes7pace"
                :workItemsAdaptiveWork="workItemsAdaptiveWork"
                :selected7paceEmployees="selected7paceEmployees"
                v-on:updateSelected7paceEmployees="updateSelected7paceEmployees"
            ></work-item-mapping>
        </v-tab-item>

        <v-tab-item 
            class="pa-10"
        >
            <v-card>
                <v-card-title>
                    <h2>
                        Update Adaptive Work Hours
                    </h2>
                </v-card-title>
                <v-card-text>
                    <!-- <v-btn @click="logWorklogs">Log values</v-btn> -->
                    <v-row v-if="canUpdateAdaptiveWork">
                        <v-col>
                            <h3>
                                Update Adaptive Work Hours to reflect 7pace Hour Entries
                            </h3>
                            <br>
                            <h3>
                                Make sure the dates under Choose Dates on the left correspond to the Pay Period you are interested in.
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="canUpdateAdaptiveWork">
                            <v-btn @click="updateAdaptiveWork" class="ma-3">Update Adaptive Work</v-btn>
                        </v-col>
                        <v-col v-else>
                            Must Create Employee CSV Mapping and Epic CSV Mapping
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-tab-item>
        </v-tabs>
    </v-row>

    <v-row justify="center">
        <v-dialog
            v-model="updateDatesDialogSuccess"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Success!
                </v-card-title>
                <v-card-text>
                    Work Log Dates have been Successfully Updated <br/><br/>
                    Start Date: {{ payrollWorkLogStartDate }} <br/>
                    End Date: {{ payrollWorkLogEndDate }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="updateDatesDialogSuccess = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="updateDatesDialogFailure"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Error
                </v-card-title>
                <v-card-text>
                    There was an issue saving the Start and End Dates :/
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="updateDatesDialogFailure = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="employeeMappingDialogSuccess"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Success!
                </v-card-title>
                <v-card-text>
                    Employee CSV Mapping Successfully Updated
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="employeeMappingDialogSuccess = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="employeeMappingDialogFailure"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Error
                </v-card-title>
                <v-card-text>
                    There was an issue saving the Employee CSV Mapping
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="employeeMappingDialogFailure = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="epicMappingDialogSuccess"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Success!
                </v-card-title>
                <v-card-text>
                    Epic CSV Mapping Successfully Updated
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="epicMappingDialogSuccess = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="epicMappingDialogFailure"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Error
                </v-card-title>
                <v-card-text>
                    There was an issue saving the Epic CSV Mapping
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="epicMappingDialogFailure = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="dialogLoading"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Loading!
                </v-card-title>
                <v-card-text>
                    <v-row
                        justify="center"
                        class="pa-5"
                    >
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            :size="70"
                            :width="7"
                        ></v-progress-circular>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="updateAdaptiveWorkDialogSuccess"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Success!
                </v-card-title>
                <v-card-text>
                    CSV Successfully Created
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="updateAdaptiveWorkDialogSuccess = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            v-model="updateAdaptiveWorkDialogError"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Error
                </v-card-title>
                <v-card-text>
                    There was an issure creating the CSV
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="updateAdaptiveWorkDialogError = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</v-container>
</template>

<script>
// import LaborCodeMapping from '../components/LaborCodeMapping.vue'
import WorklogDates from '../components/WorklogDates.vue'
import WorkItemMapping from '../components/WorkItemMapping.vue'

import { mapState, mapActions } from 'vuex'
import { DB, doc, getDoc, setDoc } from '@/firebase/db'
import axios from 'axios'

export default {
    name: 'RegistrationLanding',
    data() {
        return {
            adaptiveWorkEmployeesRequest: [],
            updateDatesDialogSuccess: false,
            updateDatesDialogFailure: false,
            laborCodeMappingKey: 1,
            workItemMappingKey: 1,
            worklogDatesMappingKey: 1,
            dialogLoading: false,
            updateAdaptiveWorkDialogSuccess: false,
            updateAdaptiveWorkDialogError: false,
            employeeMappingDialogSuccess: false,
            employeeMappingDialogFailure: false,
            epicMappingDialogSuccess: false,
            epicMappingDialogFailure: false,
            workLogs: [],
            organization: 'nlsnow',
            project: 'BriteCore Technologies',
            team: 'BriteCore Technologies Team',
            ids: [],
            workItems: [],
            employeesAdo: [],
            employees7pace: [],
            activityTypes7pace: [],
            selected7paceEmployees: [],
            employeesAdaptiveWork: [],
            departmentsPaycor: [],
            csv: [],
            numAuthAttempts: 0,
            workItemCategories: [],
            epics: [],
            epicWorkItemMapping: [],
            workItemsAdaptiveWork: [],
            payrollWorkLogStartDate: new Date(Date.now() - 12096e5).toISOString().substring(0,10),
            payrollWorkLogEndDate: new Date(Date.now()).toISOString().substring(0,10),
        }
    },
    computed: {
        ...mapState(['user']),
        uid() {
            return this.user.uid
        },
        canUpdateAdaptiveWork() {
            if (this.selected7paceEmployees && this.epicWorkItemMapping && this.selected7paceEmployees.length > 0 && this.epicWorkItemMapping.length > 0) {
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions(['monitorAuthState', 'refreshAccessTokenPaycor', 'refreshSessionIdAdaptiveWorkSandbox']),

        logWorklogs() {
            console.log('this.canUpdateAdaptiveWork:', this.canUpdateAdaptiveWork)
            console.log('this.workLogs:', this.workLogs)
            console.log('this.epicWorkItemMapping:', this.epicWorkItemMapping)
            console.log('this.workItemsAdaptiveWork:', this.workItemsAdaptiveWork)
            console.log('this.workItemsAdaptiveWork:', this.workItemsAdaptiveWork.find((workItem) => {
                return workItem.id == '/Project/bc60d1cd-45e6-4275-b292-6ce2b0f4d6b3'
            }))
            console.log('this.workItemsAdaptiveWork:', this.workItemsAdaptiveWork.filter((workItem) => {
                return workItem.ParentProject
            }))
            console.log('this.epics:', this.epics)
            console.log('this.selected7paceEmployees:', this.selected7paceEmployees)
        },

        async updateSelectedEmployees(){
            this.dialogLoading = true;
            await this.setAdaptiveWorkEmployeesRequest()

            await this.getEmployeesAdaptiveWorkLive()

            if (this.selected7paceEmployees.length > 0) {
                await this.setMappedAdaptiveWorkEmployee()
                await this.saveSelected7paceEmployees()
                this.dialogLoading = false;
                this.employeeMappingDialogSuccess = true
            } else {
                this.dialogLoading = false;
                this.employeeMappingDialogFailure = true;
            }
        },

        async setAdaptiveWorkEmployeesRequest() {
            return new Promise((resolve, reject) => {
                this.selected7paceEmployees.forEach(element => {
                    this.adaptiveWorkEmployeesRequest.push({
                        "leftExpression":{ "fieldName": "Email"},
                        "operator": "Equal",
                        "rightExpression": { "value": element.email }
                    })
                })
                if (this.adaptiveWorkEmployeesRequest.length > 0) {
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        },

        removeEmployee (item) {
            const selected7paceEmployeesIndex = this.selected7paceEmployees.findIndex((emp) => {
                return item.displayName === emp.displayName
            })
            if (selected7paceEmployeesIndex >= 0) this.selected7paceEmployees.splice(selected7paceEmployeesIndex, 1)
        },

        customEmployeeFilter(item, queryText) {
            let displayNameText = item.displayName ? item.displayName.toLowerCase() : false
            return displayNameText ? displayNameText.indexOf(queryText) > -1 : false
        },

        async saveSelected7paceEmployees() {
            return new Promise((resolve, reject) => {
                setDoc(doc(DB, 'user', this.user.uid), {
                    selected7paceEmployees: this.selected7paceEmployees
                }, { merge: true })
                    .then(() => {
                        resolve(true)
                    }).catch(() => {
                        this.employeeMappingDialogFailure = true
                        reject(false)
                    })
            })
        },

        async updateEpicMapping() {
            this.dialogLoading = true;
            await this.getEpicWorkItemMapping()

            if (this.epicWorkItemMapping.length > 0) {
                await this.setMappedWorkItems()
                this.dialogLoading = false;
                this.epicMappingDialogSuccess = true
                this.laborCodeMappingKey = this.laborCodeMappingKey + 1 // Causes LaborCodeMapping component to re-render (https://michaelnthiessen.com/force-re-render/)
            } else {
                this.dialogLoading = false;
                this.employeeMappingDialogFailure = true
            }
        },

        async updateSelected7paceEmployees() {
            this.dialogLoading = true;
            await this.getSelected7paceEmployees()

            if (this.selected7paceEmployees.length > 0) {
                this.dialogLoading = false;
                this.epicMappingDialogSuccess = true
                this.workItemMappingKey = this.workItemMappingKey + 1 // Causes LaborCodeMapping component to re-render (https://michaelnthiessen.com/force-re-render/)
            } else {
                this.dialogLoading = false;
                this.employeeMappingDialogFailure = true
            }
        },

        async saveEpicWorkItemMapping() {
            return new Promise((resolve, reject) => {
                setDoc(doc(DB, 'user', this.user.uid), { epicWorkItemMapping: this.epicWorkItemMapping }, { merge: true })
                    .then(() => {
                        this.epicMappingDialogSuccess = true
                        resolve(true)
                    }).catch(() => {
                        this.employeeMappingDialogFailure = true
                        reject(false)
                    })
            })
        },

        async get7paceUsers() {
            return new Promise((resolve, reject) => {
                axios.get('https://nlsnow.timehub.7pace.com/api/rest/users?$count=500&$skip=0&$expand=user.displayName&api-version=3.2', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                    }
                }).then((res) => {
                    this.employees7pace = res.data.data ? res.data.data : []
                    resolve(true)
                }).catch((err) => {
                    alert('Function get7paceUsers() Error:\n   ' + err.message)
                    reject(false)
                })
            })
        },

        async get7paceActivityTypes() {
            return new Promise((resolve, reject) => {
                axios.get('https://nlsnow.timehub.7pace.com/api/rest/activityTypes?api-version=3.2', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                    }
                }).then((res) => {
                    this.activityTypes7pace = res.data.data.activityTypes ? res.data.data.activityTypes : []
                    resolve(true)
                }).catch((err) => {
                    alert('Function get7paceUsers() Error:\n   ' + err.message)
                    reject(false)
                })
            })
        },

        async getWorkItemsAdaptiveWorkAndRelatedUsersLive() {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_ADAPTIVE_WORK_BASE_URL_LIVE}/data/entityQuery`,{
                    "typeName": "WorkItem",
                    "fields": ["Name","Manager.Name","DueDate","ExternalID", "Reportable", "Project", "ParentProject"],
                    "paging": {"limit": 1000},
                    "relations": [{
                        "name": "UserResources",
                        "fields": ["DisplayName","Email","ExternalID"]
                    }]
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `ApiKey ${process.env.VUE_APP_ADAPTIVE_WORK_API_KEY_LIVE}`,
                        'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                    }
                }).then((res) => {
                    this.workItemsAdaptiveWork = res.data.entities.filter((res) => {
                        return res.Name != 'Restricted'
                        // return res.Name != 'Restricted' && res.Reportable === true
                    })
                    resolve(true)
                }).catch((err) => {
                    alert('Function getWorkItemsAdaptiveWorkAndRelatedUsersLive(): ' + err.message)
                    reject(false)
                })
            })
        },

        async createTimesheetBulkAdaptiveWorkLive(requests) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_ADAPTIVE_WORK_BASE_URL_LIVE}/bulk/execute`,{
                    "requests": requests
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `ApiKey ${process.env.VUE_APP_ADAPTIVE_WORK_API_KEY_LIVE}`,
                        'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                    }
                }).then((res) => {
                    console.log(res)
                    resolve(true)
                }).catch((err) => {
                    alert('Function createTimesheetBulkAdaptiveWorkLive(): ' + err.message)
                    reject(false)
                })
            })
        },

        async getEmployeesAdaptiveWorkLive() {
            return new Promise((resolve, reject) => {
                if(this.adaptiveWorkEmployeesRequest.length > 0) {
                    axios.post(`${process.env.VUE_APP_ADAPTIVE_WORK_BASE_URL_LIVE}/data/entityQuery`,{
                        "typeName": "User",
                        "fields": ["DisplayName","Email","ExternalID"],
                        "paging": {"limit": 50},
                        "where": {
                            "or": this.adaptiveWorkEmployeesRequest
                        }
                    }, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `ApiKey ${process.env.VUE_APP_ADAPTIVE_WORK_API_KEY_LIVE}`,
                            'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN
                        }
                    }).then((res) => {
                        this.employeesAdaptiveWork = res.data.entities.map((res) => {
                            return res
                        })
                        resolve(true)
                    }).catch((err) => {
                        alert('Function getEmployeesAdaptiveWorkLive(): ' + err.message)
                        reject(false)
                    })
                } else {
                    reject(false)
                }
            })
        },
        
        async getSelected7paceEmployees() {
            return new Promise((resolve, reject) => {
                getDoc(doc(DB, 'user', this.user.uid))
                    .then((userSnap) => {
                        this.selected7paceEmployees = userSnap.data().selected7paceEmployees ? userSnap.data().selected7paceEmployees : []
                        resolve(true)
                    }).catch((err) => {
                        alert('Function getSelected7paceEmployees() error: \n\n  Unable to get employee mapping.\n\n', err.message)
                        reject(false)
                    })
            })
        },

        // Could this be set after this.selected7paceEmployees is set ^^^ and that could be why they don't always map?
        // Updates this.employees7pace JSON<Array> with a mappedAdaptiveWorkEmployee property.
        //      The mappedAdaptiveWorkEmployee property is used in the EmployeeCsvMapping Component to 
        //      populate the employee name dropdown with data from Paycor.
        setMappedAdaptiveWorkEmployee() {
            return new Promise((resolve, reject) => {
                this.selected7paceEmployees.forEach((mapping) => {
                    let employee7paceIndex = this.employees7pace.findIndex(emp => emp.id === mapping.id)
                    let employeeAdaptiveWorkIndex = this.employeesAdaptiveWork.findLastIndex(emp => emp.Email.toLowerCase() === mapping.email.toLowerCase())
                    if (employee7paceIndex >= 0 && employeeAdaptiveWorkIndex >= 0) {
                        this.employees7pace[employee7paceIndex].mappedAdaptiveWorkEmployee = this.employeesAdaptiveWork[employeeAdaptiveWorkIndex]
                    } else {
                        reject(false)
                    }
                })
                return resolve(true)
            })
        },

        setMappedWorkItems() {
            return new Promise((resolve) => {
                this.epicWorkItemMapping.forEach((mapping) => {
                    let epicId7paceIndex = this.epics.findIndex(ep => ep.System_Id === mapping.epicId7pace)

                    if (mapping.workItemIdActivityTypeIdMapping) {
                        this.epics[epicId7paceIndex].mappedWorkItemActivityType = []
                        this.epics[epicId7paceIndex].selectableActivityTypes = JSON.parse(JSON.stringify(this.activityTypes7pace))
                        
                        mapping.workItemIdActivityTypeIdMapping.forEach((mapping) => {
                            let workItemIndex = this.workItemsAdaptiveWork.findIndex((workItem) => workItem.ExternalID === mapping.workItemId)
                            let activityTypeIndex = this.activityTypes7pace.findIndex((activityType) => activityType.id === mapping.activityTypeId)
                            let selectableActivityTypeIndexToRemove = this.epics[epicId7paceIndex].selectableActivityTypes.findIndex((activityType) => activityType.id === mapping.activityTypeId)

                            this.epics[epicId7paceIndex].mappedWorkItemActivityType.push({
                                workItemAdaptiveWork: this.workItemsAdaptiveWork[workItemIndex],
                                activityType7pace: this.activityTypes7pace[activityTypeIndex]
                            })

                            this.epics[epicId7paceIndex].selectableActivityTypes.splice(selectableActivityTypeIndexToRemove, 1)
                        });
                    }
                })
                resolve(true)
            })
        },

        // Updates this.employees7pace JSON<Array> with a disabled property.
        //      The disabled property is used in the EmployeeCsvMapping Component to 
        //      disallow duplicate selection of Paycor Users, enforcing a 
        //      one 7pace user to one Paycor employee ratio.
        disableSelectedEmployees() {
            this.employeesAdaptiveWork.forEach((employeePaycor) => {
                let selected = this.selected7paceEmployees.find(selected => employeePaycor.id === selected.employeeIdPaycor)
                if (selected) {
                    employeePaycor.disabled = true
                } else {
                    employeePaycor.disabled = false
                }
            })
            return true
        },

        mapEpicChildWorkItems() {
            return new Promise((resolve) => {
                this.workLogs.forEach((workLog) => {
                    if (workLog.WorkItem != null && workLog.WorkItem.HierarchyPath) {
                        this.epics.some((epic) => {
                            let regex = new RegExp(String(epic.System_Id), 'g')
        
                            if (workLog.WorkItem.HierarchyPath.match(regex) && epic.childWorkItems) {
                                epic.childWorkItems.push(workLog.WorkItem.System_Id)
                            } else if (workLog.WorkItem.HierarchyPath.match(regex)) {
                                epic.childWorkItems = []
                                epic.childWorkItems.push(workLog.WorkItem.System_Id)
                            }
                        })
                    }
                })
                resolve(true)
            })
        },

        async updateAdaptiveWork() {
            try {
                console.log(this.selected7paceEmployees)
                console.log(this.workLogs)
                console.log(this.workItems)
                console.log(this.employeesAdaptiveWork)
                console.log(this.workItemCategories)
                console.log(this.epicWorkItemMapping)

                let requests = []

                this.dialogLoading = true

                this.workLogs.forEach((workLog) => {
                    let employee = this.selected7paceEmployees.find((emp) => {
                        return workLog.UserId === emp.id
                    })
                    let workItemId
                    let awEmpExtId
                    let Duration
                    if (employee) {
                        let epic = this.epics.find((epic) => {
                            if(epic.childWorkItems) {
                                return epic.childWorkItems.includes(workLog.WorkItemId)
                            }
                            return false
                        })
                        workItemId = Object.entries(employee.adaptiveWorkItemMapping).find(([key, value]) => {
                            return key != undefined && workLog.ActivityTypeId === value.activityTypeId && epic.System_Id === value.epicId
                        }) ? Object.entries(employee.adaptiveWorkItemMapping).find(([key, value]) => {
                            return key != undefined && workLog.ActivityTypeId === value.activityTypeId && epic.System_Id === value.epicId
                        })[0] : false
                        awEmpExtId = employee.mappedAdaptiveWorkEmployee.ExternalID
                        Duration = (workLog.PeriodLength/3600).toFixed(2)
                        if (workItemId && awEmpExtId && Duration) {
                            requests.push({
                                "url": "/data/objects/Timesheet",
                                "method": "PUT",
                                "body": {
                                    "ReportedBy": awEmpExtId,
                                    "Duration": Duration+'h',
                                    "WorkItem": workItemId,
                                    "ReportedDate": workLog.WorklogDate.Year + '-'+ workLog.WorklogDate.Month +'-'+ workLog.WorklogDate.Day
                                }
                            })
                        }
                    }
                })

                await this.createTimesheetBulkAdaptiveWorkLive(requests)

                this.dialogLoading = false
                this.updateAdaptiveWorkDialogSuccess = true
            } catch {
                this.dialogLoading = false
                this.updateAdaptiveWorkDialogError = true
            }
        },

        convertJsonToCsv(object) {
            const headers = Object.keys(object[0]).toString();

            const main = object.map((item) => {
                return Object.values(item).toString()
            })

            const csv = [headers, ...main].join('\n')

            this.startCsvDownload(csv)
        },

        startCsvDownload(input) {
            const blob = new Blob([input], { type: 'application/csv' })
            const url = URL.createObjectURL(blob)
            const date = new Date()
            const dateString = (date.getMonth() + 1).toString() + '_' + (date.getDate()).toString() + '_' + (date.getFullYear()).toString()


            const a = document.createElement('a')
            a.download = `paycor_hours_import_${dateString}.csv`
            a.href = url

            document.body.appendChild(a)

            a.click()

            a.remove()

            URL.revokeObjectURL(blob)
        },

        // WorklogDates
        async updateWorklogDates(userDataUpdated) {
            let startEndDatesLoaded = false;
            let worklogs7paceUpdated = false;
            this.dialogLoading = true;
            if (userDataUpdated) {
                startEndDatesLoaded = await this.loadStartEndDates() // get start and end dates
                worklogs7paceUpdated = await this.get7paceWorklogs() // update this.worklogs
                this.worklogDatesMappingKey = this.worklogDatesMappingKey + 1 // Causes worklogDates component to re-render (https://michaelnthiessen.com/force-re-render/)
            } else {
                alert('Issue Updating Start and End Dates')
            }
            if (startEndDatesLoaded && worklogs7paceUpdated && userDataUpdated) {
                this.dialogLoading = false;
                this.updateDatesDialogSuccess = true;
            } else {
                this.updateDatesDialogFailure = true;
                this.dialogLoading = false;
            }
        },

        // WorklogDates
        async get7paceWorklogs() {
            return new Promise((resolve, reject) => {
                axios.get(`https://nlsnow.timehub.7pace.com/api/odata/v3.2/workLogsWorkItems?$filter=Timestamp ge ${this.payrollWorkLogStartDate} and Timestamp le ${this.payrollWorkLogEndDate}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                    }
                }).then((res) => {
                    this.workLogs = res.data.value
                    resolve(true)
                }).catch((err) => {
                    alert('Function get7paceWorklogs() error:\n\n  ' + err.message)
                    reject(false)
                })
            })

        },

        // Called on mount() hook only
        async getAndMapEmployees7paceAdaptiveWork() {
            await this.get7paceUsers() // Sets this.employees7pace
            await this.get7paceActivityTypes() // Sets this.employees7pace
            await this.getSelected7paceEmployees() // Sets this.selected7paceEmployees
            await this.setAdaptiveWorkEmployeesRequest()
            await this.getEmployeesAdaptiveWorkLive() // Sets this.employeesAdaptiveWork
            if (this.selected7paceEmployees.length > 0) { // think about this? What about laborCodeMapping?
                await this.setMappedAdaptiveWorkEmployee() // updates selected7paceEmployees Array<JSON> mappedAdaptiveWorkEmployee property
                // await this.disableSelectedEmployees() // updates selected7paceEmployees Array<JSON> disabled property
            }
        },

        // Called on mount() hook and updateWorklogDates()
        async mapAdaptiveWorkWorkItems() {
            await this.getEpics() // Sets this.epics
            await this.mapEpicChildWorkItems()
            await this.getEpicWorkItemMapping() // Sets this.epicWorkItemMapping

            if (this.epicWorkItemMapping.length > 0) {
                await this.setMappedWorkItems()
                this.laborCodeMappingKey = this.laborCodeMappingKey + 1 // Causes LaborCodeMapping component to re-render (https://michaelnthiessen.com/force-re-render/)
            }
        },

        async getEpics() {
            return new Promise((resolve, reject) => {
                axios.get("https://nlsnow.timehub.7pace.com/api/odata/v3.2/workItemsHierarchyAllLevels?workItemsFilter=System_WorkItemType eq 'Epic' and System_TeamProject eq 'BriteCore Technologies' or System_Id eq 30432", {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                    }
                }).then((res) => {
                    this.epics = res.data.value
                    resolve(true)
                }).catch((err) => {
                    alert('Function getEpics() error:\n\n  ' + err.message)
                    reject(false)
                })
            })
        },

        // here
        async getEpicWorkItemMapping() {
            return new Promise((resolve, reject) => {
                getDoc(doc(DB, 'user', this.user.uid))
                    .then(async (userSnap) => {
                        this.epicWorkItemMapping = userSnap.data().epicWorkItemMapping
                        resolve(true)
                    }).catch(() => {
                        alert('Function getEpicWorkItemMapping() error: \n\n  Unable to get employee mapping')
                        reject(false)
                    })
            })
        },

        // WorklogDates
        async loadStartEndDates() {
            return new Promise((resolve, reject) => {
                getDoc(doc(DB, 'user', this.user.uid))
                    .then((userSnap) => {
                        if (userSnap.data().payrollWorkLogStartDate && userSnap.data().payrollWorkLogEndDate) {
                            this.payrollWorkLogStartDate = userSnap.data().payrollWorkLogStartDate
                            this.payrollWorkLogEndDate = userSnap.data().payrollWorkLogEndDate
                        }
                        resolve(true);
                    }).catch(() => {
                        reject(false);
                    })
            })
        },

    },
    components: {
        // LaborCodeMapping,
        WorklogDates,
        WorkItemMapping,
    },
    async mounted() {
        await this.monitorAuthState()
        // 1.) Get correct Worklogs
            // i.) this.startDate
            // ii.) this.endDate
            // Need to be set
        await this.loadStartEndDates() // sets this.payrollWorkLogStartDate this.payrollWorkLogEndDate
        await this.get7paceWorklogs()
        await this.getAndMapEmployees7paceAdaptiveWork()
        await this.getWorkItemsAdaptiveWorkAndRelatedUsersLive()
        if (this.workItemsAdaptiveWork.length > 0) {
            await this.mapAdaptiveWorkWorkItems()
        } else {
            debugger
        }
    },
    watch: {
        // uid: async function() {
        //     await this.loadStartEndDates() // sets this.startDate and this.endDate to this.payrollWorkLogStartDate this.payrollWorkLogEndDate
        //     await this.get7paceWorklogs()
        // },

    }
    
}
</script>

<style scoped>

</style>
