<template>
    <v-card>
        <v-card-title v-if="useSandbox">
            Paycor Sandbox OAuth Flow
        </v-card-title>
        <v-card-title v-else>
            Paycor OAuth Flow
        </v-card-title>
        <v-card-text v-if="useSandbox">
            <v-btn v-if="(!$route.query.code || $route.query.state != 'paycor') && !refreshTokenSetSandbox" :href="paycorAuthorizationUrlSandbox" class="ma-3">Authorize Paycor Sandbox Application</v-btn>
            <v-btn v-if="$route.query.code && $route.query.state === 'paycor' && !refreshTokenSetSandbox" @click="requestAccessTokenPaycorSandbox" class="ma-3">Request Paycor Sandbox Access Token</v-btn>
            <v-btn v-if="refreshTokenSetSandbox" @click="refreshAccessTokenPaycorSandbox" class="ma-3">Refresh Paycor Sandbox Access Token</v-btn>
        </v-card-text>
        <v-card-text v-else>
            <v-btn v-if="(!$route.query.code || $route.query.state != 'paycor') && !refreshTokenSet" :href="paycorAuthorizationUrlLive" class="ma-3">Authorize Paycor Application</v-btn>
            <v-btn v-if="$route.query.code && $route.query.state === 'paycor' && !refreshTokenSet" @click="requestAccessTokenPaycor" class="ma-3">Request Paycor Access Token</v-btn>
            <v-btn v-if="refreshTokenSet" @click="refreshAccessTokenPaycor" class="ma-3">Refresh Paycor Access Token</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DB, doc, setDoc } from '@/firebase/db'
import { paycorOAuthPost, paycorOAuthPostSandbox } from '../firebase/functions'

export default {
    name: 'PaycorOauth',
    data() {
        return {
            refreshTokenSet: false,
            refreshTokenSetSandbox: false,
        }
    },
    computed: {
        ...mapState(['user']),
        paycorAuthorizationUrlSandbox() {
            return `${process.env.VUE_APP_PAYCOR_URL_BASE_SANDBOX}?client_id=${process.env.VUE_APP_PAYCOR_CLIENT_ID_SANDBOX}&scope=${process.env.VUE_APP_PAYCOR_SCOPE_SANDBOX}+offline_access&response_type=code&state=${process.env.VUE_APP_PAYCOR_STATE}&redirect_uri=${process.env.VUE_APP_PAYCOR_REDIRECT_URI_SANDBOX}&code_challenge=${process.env.VUE_APP_PAYCOR_CODE_CHALLENGE_SANDBOX}&code_challenge_method=${process.env.VUE_APP_PAYCOR_CODE_CHALLENGE_METHOD}`
        },
        paycorAuthorizationUrlLive() {
            return `${process.env.VUE_APP_PAYCOR_URL_BASE_LIVE}?client_id=${process.env.VUE_APP_PAYCOR_CLIENT_ID_LIVE}&scope=${process.env.VUE_APP_PAYCOR_SCOPE_LIVE}+offline_access&response_type=code&state=${process.env.VUE_APP_PAYCOR_STATE}&redirect_uri=${process.env.VUE_APP_PAYCOR_REDIRECT_URI_LIVE}&code_challenge=${process.env.VUE_APP_PAYCOR_CODE_CHALLENGE_SANDBOX}&code_challenge_method=${process.env.VUE_APP_PAYCOR_CODE_CHALLENGE_METHOD}`
        },
    },
    methods: {
        ...mapActions(['updateUserState', 'refreshAccessTokenPaycor', 'refreshAccessTokenPaycorSandbox']),

        logPaycorAuthorizationUrlSandbox() {
            console.log(this.paycorAuthorizationUrlSandbox)
            // https://hcm.paycor.com/AppActivation/Authorize?client_id=153af70ddfe0496faf10&scope=e1e59fb31515ee11a9bb000d3ad38328+offline_access&response_type=code&state=paycor&redirect_uri=https://time.nlsnow.com/registration-landing&code_challenge=X1H9qPo0nVY4h0DHuVwbnZ3b39Ehy71DfU894zwm44U&code_challenge_method=s256
        },

        logPaycorAuthorizationUrlLive() {
            console.log(this.paycorAuthorizationUrlLive)
            // https://hcm.paycor.com/AppActivation/Authorize?client_id=153af70ddfe0496faf10&scope=e1e59fb31515ee11a9bb000d3ad38328+offline_access&response_type=code&state=paycor&redirect_uri=https://time.nlsnow.com/registration-landing&code_challenge=X1H9qPo0nVY4h0DHuVwbnZ3b39Ehy71DfU894zwm44U&code_challenge_method=s256
        },
        
        async requestAccessTokenPaycor () {
            const res = await paycorOAuthPost({
                authCode: this.$route.query.code
            })
            console.log('res:', res)
            const userRef = await doc(DB, 'user', this.user.uid)
            await setDoc(userRef, {
                accessTokenPaycor: res.data.access_token,
                refreshTokenPaycor: res.data.refresh_token
            }, { merge: true })
            await this.updateUserState()
            this.setRefreshToken()
        },
        
        async requestAccessTokenPaycorSandbox () {
            const res = await paycorOAuthPostSandbox({
                authCode: this.$route.query.code
            })
            console.log('res:', res)
            const paycorRef = await doc(DB, 'paycorOAuth/inLYHNI3QtERmKyWQ49f')
            await setDoc(paycorRef, {
                accessTokenPaycorSandbox: res.data.access_token,
                refreshTokenPaycorSandbox: res.data.refresh_token
            }, { merge: true })
            await this.updateUserState()
            this.setSandboxRefreshToken()
        },

        setRefreshToken() {
            if (this.user.refreshTokenPaycor) {
                this.refreshTokenSet = true
            }
        },

        setSandboxRefreshToken() {
            if (this.user.refreshTokenPaycorSandbox) {
                this.refreshTokenSetSandbox = true
            }
        }
    },
    mounted() {
        this.updateUserState()
        this.setRefreshToken()
        this.setSandboxRefreshToken()
    },
    props: {
        useSandbox: {
            type: Boolean,
            default() {
                return false
            }
        }
    }
}
</script>