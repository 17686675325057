<template>
    <v-card>
        <v-card-title>
            <h2>
                Hazard Hub YoC by Policy Number
            </h2>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <h3>
                        1) Download the SQL Query.
                    </h3>
                    <h3>
                        2) Run Downloaded Query on the Great Bay Database.
                    </h3>
                    <h3>
                        3) Export output as a CSV.
                    </h3>
                    <h3>
                        4) Upload CSV below
                    </h3>
                    <h3>
                        5) Click "Create Hazard Hub YoC by Policy Number" Button.
                    </h3>
                    <br />
                    <h3>
                        **Report will automatically download.
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        @click="downloadSql()"
                    >Download SQL Query</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col max-height="500px">
                    <v-file-input
                        v-model="queryCsv"
                        show-size
                        label="MySQL Query CSV"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h3>
                        Upload CSV output of MySQL Query run script to get back Hazard Hub YoC by Policy Number
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        @click="createPolicyTermPremiumTrendsReport()"
                    >Create Hazard Hub YoC by Policy Number</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
    
<script>

import { mapState, mapActions } from 'vuex'

import { uploadToAttachmentsFolder } from '@/firebase/functions'

export default {
        name: 'GreatbayYocByPolicyNumber',
        data() {
            return {
                queryCsv: '',
                uniquePolicyNumbers: [],
                maxItr: 0,
            }
        },
        computed: {
            ...mapState(['user']),
        },
        methods: {
            ...mapActions(['monitorAuthState']),

            async createPolicyTermPremiumTrendsReport() {
                const reader = new FileReader()
                reader.readAsText(this.queryCsv)
                reader.onload = () => {
                    this.startCsvDownload(reader.result)
                    this.uploadToAttachmentsFolderGreatBay(reader.result)
                }
            },

            getPosition(string, subString, index) {
                return string.split(subString, index).join(subString).length;
            },

            isJson(str) {
                try {
                    return JSON.parse(str);
                } catch (e) {
                    return str;
                }
            },

            csvJSON(csv) {
                const lines = csv.split('\n')
                const result = []
                const headers = lines[0].split(',')

                for (let i = 1; i < lines.length; i++) {
                    let firstCommaIndex = this.getPosition(lines[i], ',', 1)
                    let secondCommaIndex = this.getPosition(lines[i], ',', 2)
                    let thirdCommaIndex = lines[i].indexOf(',"{"questions"') // Can be NULL
                    if (!lines[i])
                        continue
                    const obj = {}
                    let parsedLine = thirdCommaIndex > -1 ? this.isJson(lines[i].substring(secondCommaIndex + 2, thirdCommaIndex - 1)) : 'NULL'
                    let parsedLine2 = thirdCommaIndex > -1 ? this.isJson(lines[i].substring(thirdCommaIndex + 2, lines[i].length - 1)) : 'NULL'
                    const currentline = [lines[i].substring(0, firstCommaIndex), lines[i].substring(firstCommaIndex + 1, secondCommaIndex), parsedLine, parsedLine2]
                    let check = lines[i].substring(0, firstCommaIndex) === "\"Coverage A - Dwelling\""

                    if (parsedLine !== 'NULL' && !check && Array.isArray(parsedLine)) {
                        if (Array.isArray(parsedLine)) {
                            debugger
                        }
                        let index = parsedLine.objects.findIndex((obj) => {
                            return obj.name === "hhRequest"
                        })

                        if(index > -1) {
                            debugger
                        }
                    }
                    
                    for (let j = 0; j < headers.length; j++) {
                        obj[headers[j]] = currentline[j]
                    }
                    
                    result.push(obj)
                }
                debugger
                return result
            },

            groupBy(array, keyFn) {
                return array.reduce((result, item) => {
                const key = keyFn(item);
                if (this.uniquePolicyNumbers.indexOf(key) === -1) { 
                    this.uniquePolicyNumbers.push(key)
                }
                result[key] = result[key] || [];
                result[key].push(item);
                return result;
                }, {});
            },

            async uploadToAttachmentsFolderGreatBay(file) {
                const blob = new Blob([file], { type: 'text/csv' })
                let csvBase64 = await this.getBase64(blob)
                try {
                    let res = await uploadToAttachmentsFolder({
                        client: 'greatbay',
                        csvBase64: csvBase64,
                        fileName: "HH YoC by Policy Number.csv"
                    })
    
                    return res
                } catch(err) {
                    alert('Function uploadToAttachmentsFolder() error:\n\n  ' + err.message)
                    return err
                }
            },

            startCsvDownload(input) {
                const blob = new Blob([input], { type: 'text/csv' })
                const url = URL.createObjectURL(blob)
                const date = new Date()
                const dateString = (date.getMonth() + 1).toString() + '_' + (date.getDate()).toString() + '_' + (date.getFullYear()).toString()
    
                const a = document.createElement('a')
                a.download = `HH YoC by Policy Number (${dateString}).csv`
                a.href = url
    
                document.body.appendChild(a)
    
                a.click()
    
                a.remove()
    
                URL.revokeObjectURL(blob)
            },
            
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        console.log(reader.result);
                        resolve(reader.result.split(',')[1])
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                        reject(error)
                    };
                })
            },

            downloadSql() {
                const query = `SELECT 
    policies.policyNumber,
    COALESCE(MAX(CASE
                WHEN
                    policy_type_items.name = 'Hazard Hub'
                THEN
                    CASE
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[0].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[0].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[1].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[1].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[2].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[2].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[3].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[3].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[4].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[4].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[5].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[5].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[6].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[6].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[7].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[7].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[8].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[8].runningTotal.enhanced_property.assessment.Year_Built'))
                        WHEN
                            JSON_CONTAINS_PATH(property_items.ratingDetails,
                                    'one',
                                    '$.objects[9].runningTotal.enhanced_property.assessment.Year_Built') = 1
                        THEN
                            JSON_UNQUOTE(JSON_EXTRACT(property_items.ratingDetails,
                                            '$.objects[9].runningTotal.enhanced_property.assessment.Year_Built'))
                        ELSE NULL
                    END
            END),
            0) AS "Hazard Hub Year Built",
    COALESCE(MAX(CASE
                WHEN
                    policy_type_items.name = 'Coverage A - Dwelling'
                THEN
                    SUBSTRING_INDEX(SUBSTRING(property_items.builderObj,
                                INSTR(property_items.builderObj,
                                        '"Year of Construction": "') + 25),
                            '"',
                            1)
            END),
            0) AS 'Year of Construction'
FROM
    greatbay.policies
        JOIN
    greatbay.revisions AS revisions ON revisions.policyId = policies.id
        JOIN
    greatbay.properties AS properties ON properties.revisionId = revisions.id
        JOIN
    greatbay.property_items AS property_items ON property_items.propertyId = properties.id
        JOIN
    greatbay.policy_type_items AS policy_type_items ON policy_type_items.id = property_items.itemId
WHERE
    revisions.id = (SELECT 
            id
        FROM
            revisions AS r2
        WHERE
            r2.policyId = policies.id
                AND r2.deleted = FALSE
        ORDER BY r2.revisionDate DESC , r2.commitDate DESC , r2.createDate DESC
        LIMIT 1)
        AND properties.addressLine1 IS NOT NULL
        AND property_items.deleted = FALSE
GROUP BY policyNumber
ORDER BY policyNumber DESC;`

                const blob = new Blob([query], { type: 'application/sql' })
                const url = URL.createObjectURL(blob)
    
                const a = document.createElement('a')
                a.download = `Policy_Term_Premium_Trends_Query.sql`
                a.href = url
    
                document.body.appendChild(a)
    
                a.click()
    
                a.remove()
    
                URL.revokeObjectURL(blob)
            },
            
        }
    }
</script>

<style scoped>

</style>
