<!-- 
    Inputs from PayrollCsv:
        1) selectedEpics7pace
        2) epics
        3) epicWorkItemMapping
        4) workItemsAdaptiveWork
        
    Affects in PayrollCsv:
        1) epicWorkItemMapping
        2) epics

    Goal: 
        1) 
        2) 
 -->
 
 <template>
    <div>
        <v-row class="my-3">
            <v-col>
                <v-card>
                    <v-card-title class="text-center justify-center py-6">
                        <h2>
                            Employee / Work Item / Epic / Activity Type Mapping
                        </h2>
                    </v-card-title>
                    <v-tabs
                        v-model="tab"
                        class="pr-15"
                        center-active
                        grow
                        right
                    >
                        <v-tab  
                            v-for="(employee, index) in selected7paceEmployees"
                            :key="index"
                        >
                            {{ employee.displayName }}
                        </v-tab>
                    </v-tabs>
                    <v-card-text>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            v-for="(employee, index) in selected7paceEmployees"
                            :key="index"
                        >
                            <v-card
                                color="basil"
                                flat
                            >
                            <v-card-text>
                                <v-row v-for="(workItem, index) in employeeWorkItems(employee.mappedAdaptiveWorkEmployee.ExternalID)" :key="index">
                                    <v-row>
                                        <v-col v-if="workItem.Reportable" cols="12">
                                            {{ parentProjectName(workItem) }} {{ workItem.Name }}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="workItem.Reportable">
                                        <v-col cols="6">
                                            <v-autocomplete
                                                :value="epic(employee, workItem.ExternalID)"
                                                :filter="customEpicFilter"
                                                :items="epics"
                                                label="Epic"
                                                @change="epicSelected($event.System_Id, employee, workItem.ExternalID)"
                                            >
                                                <template v-slot:selection="{ item }">
                                                    {{ item.System_Title }}
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    {{ item.System_Title }}
                                                </template>
                                            </v-autocomplete>
                                        </v-col>

                                        <v-col cols="6">
                                            <v-autocomplete
                                                :value="activityType(employee, workItem.ExternalID)"
                                                :filter="customFilter2"
                                                :items="activityTypes"
                                                label="Activity Types"
                                                @change="activityTypeSelected($event.id, employee, workItem.ExternalID)"
                                            >
                                                <template v-slot:selection="{ item }">
                                                    {{ item.name }}
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    {{ item.name }}
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="workItem.Reportable">
                                    </v-row>
                                </v-row>
                                <v-row>
                                    <v-col class="text-right pa-10">
                                        <v-btn @click="updateSelected7paceEmployees">Save Epic and Work Item Mapping</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                        
                        <h3>
                            Mapping Work Items to 7pace ADO Epics
                        </h3>

                        <!-- {{ selected7paceEmployees }} -->
                        <!-- epics.length: {{ epics.length }}
                                        <br>
                                        <br>
                                        epicWorkItemMapping.length: {{ epicWorkItemMapping.length }}
                                        <br>
                                        <br>
                                        activityTypes.length: {{ activityTypes.length }}
                                        <br>
                                        <br>
                                        workItemsAdaptiveWork.length: {{ workItemsAdaptiveWork.length }}
                                        <br>
                                        <br> -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
    
<script>
import { mapState } from 'vuex';
import { DB, doc, setDoc } from '@/firebase/db'

export default {
    name: 'WorkItemMapping',
    data() {
        return {
            tab: null,
        }
    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
        customEpicFilter(item, queryText) {
            let systemTitleText = item.System_Title ? item.System_Title.toLowerCase() : false
            return systemTitleText ? systemTitleText.indexOf(queryText) > -1 : false
        },
        customFilter2(item, queryText) {
            let code = item.name ? item.name.toLowerCase() : false
            return (code ? code.indexOf(queryText.toLowerCase()) > -1 : false)
        },
        parentProjectName(item) {
            let parentProject;
            if (item.ParentProject){
                parentProject = this.workItemsAdaptiveWork.find((workItem) => {
                    return workItem.id == item.ParentProject.id
                })
            }
            return parentProject ? 'Parent Project: ' + parentProject.Name + ' | ' : ''
        },
        employeeWorkItems(adaptiveWorkEmployeeId) {
            console.log(adaptiveWorkEmployeeId)
            let workItems = this.workItemsAdaptiveWork.filter((workItem) => {
                if (workItem.UserResources && workItem.UserResources.entities) {
                    return workItem.UserResources.entities.find((user) => user.ExternalID === adaptiveWorkEmployeeId)
                } else {
                    return false
                }
            })
            workItems.forEach(element => {
                let adUser = element.UserResources.entities.find((user) => user.ExternalID === adaptiveWorkEmployeeId)
                let name = element.Name
                let reportable = element.Reportable
                console.log(name)
                console.log(reportable)
                console.log(adUser)
            });
            return workItems
        },
        activityType(employee, workItemId) {
            let activityTypeId = employee.adaptiveWorkItemMapping ? 
                    employee.adaptiveWorkItemMapping[workItemId] ? 
                        employee.adaptiveWorkItemMapping[workItemId].activityTypeId ?
                            employee.adaptiveWorkItemMapping[workItemId].activityTypeId
                        : undefined
                    : undefined
                : undefined

            if (activityTypeId) {
                return this.activityTypes.find((type) => {
                    return type.id === activityTypeId
                })
            } else {
                return undefined
            }
        },
        activityTypeSelected(activityId, employee, workItemExternalId) {
            employee.adaptiveWorkItemMapping = employee.adaptiveWorkItemMapping ? employee.adaptiveWorkItemMapping : {}

            employee.adaptiveWorkItemMapping[workItemExternalId] = {
                activityTypeId: activityId,
                epicId: employee.adaptiveWorkItemMapping[workItemExternalId] ? 
                    employee.adaptiveWorkItemMapping[workItemExternalId].epicId ? 
                        employee.adaptiveWorkItemMapping[workItemExternalId].epicId : ''
                    : ''
            }
        },
        epic(employee, workItemId) {
            let epicId = employee.adaptiveWorkItemMapping ? 
                    employee.adaptiveWorkItemMapping[workItemId] ? 
                        employee.adaptiveWorkItemMapping[workItemId].epicId ?
                            employee.adaptiveWorkItemMapping[workItemId].epicId
                        : undefined
                    : undefined
                : undefined

            if (epicId) {
                return this.epics.find((type) => {
                    return type.System_Id === epicId
                })
            } else {
                return undefined
            }
        },
        epicSelected(epicId, employee, workItemExternalId) {
            employee.adaptiveWorkItemMapping = employee.adaptiveWorkItemMapping ? employee.adaptiveWorkItemMapping : {}

            employee.adaptiveWorkItemMapping[workItemExternalId] = {
                activityTypeId: employee.adaptiveWorkItemMapping[workItemExternalId] ? 
                    employee.adaptiveWorkItemMapping[workItemExternalId].activityTypeId ? 
                        employee.adaptiveWorkItemMapping[workItemExternalId].activityTypeId : ''
                    : '',
                epicId: epicId,
            }
        },
        async updateSelected7paceEmployees() {
            await this.saveSelected7paceEmployees()
            this.$emit('updateSelected7paceEmployees')
        },
        async saveSelected7paceEmployees() {
            return new Promise((resolve, reject) => {
                setDoc(doc(DB, 'user', this.user.uid), {
                    selected7paceEmployees: this.selected7paceEmployees
                }, { merge: true })
                    .then(() => {
                        resolve(true)
                    }).catch(() => {
                        this.employeeMappingDialogFailure = true
                        reject(false)
                    })
            })
        }
    },

    async mounted() {
    },

    props: {
        epics: {
            type: Array,
            default() {
                return []
            }
        },
        activityTypes: {
            type: Array,
            default() {
                return []
            }
        },
        epicWorkItemMapping: {
            type: Array,
            default() {
                return []
            }
        },
        workItemsAdaptiveWork: {
            type: Array,
            default() {
                return []
            }
        },
        selected7paceEmployees: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style scoped>

</style>
