import { render, staticRenderFns } from "./BritecoreTools.vue?vue&type=template&id=892f93d8&scoped=true&"
import script from "./BritecoreTools.vue?vue&type=script&lang=js&"
export * from "./BritecoreTools.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "892f93d8",
  null
  
)

export default component.exports