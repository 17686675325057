<template>
    <v-card>
        <v-card-title>
            OAuth Flow
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <paycor-oauth :useSandbox="useSandbox"></paycor-oauth>
                </v-col>
                <!-- <v-col>
                    <ado-oauth></ado-oauth>
                </v-col> -->
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import PaycorOauth from './PaycorOauth.vue'
// import AdoOauth from './AdoOauth.vue'

export default {
    name: 'OauthComponent',
    data() {
        return {}
    },
    computed: {},
    methods: {},
    components: {
      PaycorOauth,
    //   AdoOauth,
    },
    props: {
        useSandbox: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    mounted() {}
}
</script>